const actionName = {
  LOADING: "loading",
  USER_LIST : "USER_LIST",
  USER_PROFILE : "USER_PROFILE",
  USER_COUNTER : "USER_COUNTER",
  GET_REQUEST : "GET_REQUEST",
  GET_INFLUENCER : "GET_INFLUENCER",
  GET_INFLUENCER_EXIST : "GET_INFLUENCER_EXIST",
  GET_INFLUENCER_STATS : "GET_INFLUENCER_STATS",
  GET_DEFAULT_MSG : "GET_DEFAULT_MSG",
  GET_ALL_DATES: "GET_ALL_DATES",
  GET_DATES_STATS: "GET_DATES_STATS",
  GET_DASHBOARD_STATS: "GET_DASHBOARD_STATS",
  GET_DASHBOARDNEW_STATS: 'GET_DASHBOARDNEW_STATS',
  GET_DASHBOARDDEACTIVATE_STATS: 'GET_DASHBOARDDEACTIVATE_STATS',
  GET_EXIST_MAIL: "GET_EXIST_MAIL",
  GET_GEO_STATS: "GET_GEO_STATS",
  GET_REGCOMPFEMALE: "GET_REGCOMPFEMALE",
  GET_REGCOMPMALE: "GET_REGCOMPMALE",
  GET_REGUNCOMPFEMALE: "GET_REGUNCOMPFEMALE",
  GET_REGUNCOMPMALE: "GET_REGUNCOMPMALE",
  GET_TOKEN: "GET_TOKEN",
  GET_COUNTRY: "GET_COUNTRY",
  GET_ALL_COUNTRY: "GET_ALL_COUNTRY",
};

export default actionName;
